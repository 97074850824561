import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule, Scroll } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsFeedbackFeatureModule } from '@design-system/feature/feedback';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { BASE_PATH } from '@paldesk/shared-lib/data-access/palipedia-service-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { IsPdIconPipeModule } from '@shared-lib/is-pd-icon-pipe';
import { LanguageSelectionModule } from '@shared-lib/language-selection';
import { ConfirmPasswordModule } from '@ui-kit/form-control';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import { filter } from 'rxjs';
import { AppWrapperComponent } from './app-wrapper.component';
import { AppWrapperService } from './app-wrapper.service';
import { AppsMenuComponent } from './apps-menu/apps-menu.component';
import { HcStatusBannerComponent } from './hc-status-banner/hc-status-banner.component';
import { HelpFeedbackMenuComponent } from './help-feedback-menu/help-feedback-menu.component';
import { PageActionsComponent } from './page-actions/page-actions.component';
import { SearchMenuComponent } from './search/search-menu/search-menu.component';
import { DocumentsMenuComponent } from './shared/documents-menu/documents-menu.component';
import { DocumentsSidebarComponent } from './shared/documents-sidebar/documents-sidebar.component';
import { MenuModule } from './sidebar-mobile/menu/menu.module';
import { SidebarMobileComponent } from './sidebar-mobile/sidebar-mobile.component';
import { AppWrapperV2Store } from './signal-store/app-wrapper-v2-signal-store';
import { SharedWrapperModule } from './shared/shared-wrapper.module';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from './config';
import { UserComponent } from './user/user.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    PdLetDirectiveModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    DsSpacingModule,
    TranslateModule,
    ConfirmPasswordModule,
    AppShortNamePipeModule,
    DsLoadingModule,
    DsPageModule,
    DsFeedbackFeatureModule,
    DsTextIndicatorModule,
    HcStatusBannerComponent,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
    MatRippleModule,
    IsPdIconPipeModule,
    DsEquipmentIconModule,
    DsSnackbarModule,
    OverlayModule,
    MatProgressSpinnerModule,
    HelpFeedbackMenuComponent,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000491,
    }),
    SidebarMobileComponent,
    DocumentsMenuComponent,
    DocumentsSidebarComponent,
    SharedWrapperModule,
    MenuModule,
    AppsMenuComponent,
    GoogleAnalytics4DirectiveModule,
    LanguageSelectionModule,
    SearchMenuComponent,
    PageActionsComponent,
  ],
  declarations: [AppWrapperComponent, UserComponent],
  exports: [AppWrapperComponent, UserComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    {
      provide: BASE_PATH,
      useFactory: (config: DsAppWrapperConfig) =>
        config.appGatewayBasePath + '/palipedia',
      deps: [DS_APP_WRAPPER_CONFIG],
    },
    MatDrawer,
    AppWrapperService,
    AppWrapperV2Store,
  ],
})
export class AppWrapperModule {
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (!e.position) {
          document.getElementById('scrollToTopRestoration')?.scroll(0, 0);
        }
      });
  }

  static forRoot(config: Provider[]): ModuleWithProviders<AppWrapperModule> {
    return {
      ngModule: AppWrapperModule,
      providers: [...config],
    };
  }
}
