import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'current',
        pathMatch: 'full',
      },
      {
        path: 'current',
        loadChildren: () =>
          import('./status-paldesk/status-paldesk.module').then(
            (m) => m.StatusPaldeskModule,
          ),
      },
      {
        path: 'past-incidents',
        loadComponent: () =>
          import(
            './status-paldesk/past-incidents/past-incidents.component'
          ).then((mod) => mod.PastIncidentsComponent),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
